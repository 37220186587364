import React from "react"
import Link from "gatsby-link"
import { FaBars } from "@react-icons/all-files/fa/FaBars"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import SubHeader from "./subHeader"
import * as headerStyles from "./header.module.scss"
import JugSaxonyLogo from "../../assets/jug-saxony-logo.svg"

const fixedPages = {
  "day": ["Call for Papers", "Infos", "Workshoptag", "Programm", "Tickets", "Sponsoren", "App"],
  "partner": ["Mitmachen", "Fördermitglieder", "Preissponsoren", "Newsletter"],
}

const fixedDestinations = {
  "day": ["/day/callforpapers", "/day/infos", "/day/workshoptag", "/day/programm", "/day/tickets", "/day/sponsoren", "/day/app"],
  "partner": ["/partner/mitmachen", "/partner", "/partner/preissponsoren", "/partner/newsletter"],
}

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showNav: false,
    }
    this.setShowNav = this.setShowNav.bind(this)
  }

  setShowNav() {
    this.setState({ showNav: !this.state.showNav })
  }

  subheaderActive() {
    const path = this.props.location.pathname
    let subheaderActive = false
    const subheaderPaths = ["day", "partner", "verein", "timeline", "camp"]
    if (path === "/") {
      return false
    }
    subheaderPaths.forEach(subheader => {
      if (path.includes(subheader)) {
        subheaderActive = true
      }
    })
    return subheaderActive
  }

  getCampDestinationNames() {
    const { campTakesPlace } = this.props
    if (campTakesPlace) {
      return ["Infos", "Programm", "Tickets", "Sponsoren", "Newsletter"]
    } else {
      return ["Infos", "Rückblick", "Newsletter"]
    }
  }

  getDestinations(root, destinationNames) {
    let destinations = []
    for (let i = 0; i < destinationNames.length; i++) {
      const destination = root + "/" + destinationNames[i].toLowerCase()
      destinations.push(destination)
    }
    return destinations
  }

  render() {
    const { showNav } = this.state
    const { subHeaderPages, subHeaderPageNames } = this.props
    const subheaderActive = this.subheaderActive()

    //verein destinations
    const vereinDestinationNames = subHeaderPageNames.verein
    const vereinDestinationNamesFromMarkdown = subHeaderPages.verein
    const vereinDestinations = this.getDestinations("/verein", vereinDestinationNamesFromMarkdown)

    //day destinations
    const dayPagesNames = subHeaderPageNames.day
    const dayDestinationNamesFromMarkdown = subHeaderPages.day
    const dayDestinationNames = fixedPages.day.concat(dayPagesNames)
    const dayDestinations = fixedDestinations.day.concat(this.getDestinations("/day", dayDestinationNamesFromMarkdown))

    //partner destinations
    const partnerPagesNames = subHeaderPageNames.partner
    const partnerDestinationNamesFromMarkdown = subHeaderPages.partner
    const partnerDestinationNames = fixedPages.partner.concat(partnerPagesNames)
    const partnerDestinations = fixedDestinations.partner.concat(this.getDestinations("/partner", partnerDestinationNamesFromMarkdown))

    //camp destinations
    const campPagesNames = subHeaderPageNames.camp
    const campDestinationNamesFromMarkdown = subHeaderPages.camp
    const campDestinationNames = this.getCampDestinationNames().concat(campPagesNames)
    const campDestinations = this.getDestinations("/camp", campDestinationNames).concat(this.getDestinations("/camp", campDestinationNamesFromMarkdown))

    //timeline destinations
    const timelineDestinationNames = subHeaderPageNames.timeline
    const timelineDestinations = subHeaderPages.timeline

    return (
      <div className={`${headerStyles.topnav} ${showNav ? headerStyles.open : headerStyles.notOpen} 
                ${subheaderActive ? headerStyles.subheaderActive : ""}`} id="myTopnav">
        <div className={headerStyles.mobileNavheader}>
          <Link className={headerStyles.navLink} to="/">
            <div className={headerStyles.logo}><JugSaxonyLogo /></div>
          </Link>
          <a className={`${headerStyles.navLink} ${headerStyles.icon}`} onClick={this.setShowNav}>
            {showNav ? <FaTimes /> : <FaBars />}
          </a>
        </div>
        <div className={`${headerStyles.headerItem} ${headerStyles.navDropdown}`}>
          <Link className={`${headerStyles.dropbtn}`} to="/verein" activeClassName={`${headerStyles.activeDropbtn}`}
                partiallyActive={true}>
            <button className={headerStyles.dropbtn}>Verein</button>
          </Link>
          <SubHeader location={this.props.location}
                     destinationNames={vereinDestinationNames}
                     destinations={vereinDestinations}
                     key="/verein" root="verein" />
        </div>
        <Link className={`${headerStyles.navLink} ${headerStyles.headerItem}`} to="/jobwall"
              activeClassName={`${headerStyles.activeFirstLink}`}>JobWall</Link>
        <div className={`${headerStyles.headerItem} ${headerStyles.navDropdown}`}>
          <Link className={`${headerStyles.dropbtn}`} to="/timeline" activeClassName={`${headerStyles.activeDropbtn}`}
                partiallyActive={true}>
            <button className={headerStyles.dropbtn}>Timeline</button>
          </Link>
          <SubHeader location={this.props.location}
                     destinationNames={timelineDestinationNames}
                     destinations={timelineDestinations}
                     isTimeline={true}
                     key="/timeline" root="timeline" />
        </div>
        <Link className={`${headerStyles.navLink} ${headerStyles.navHeading}`} to="/">
          <div className={headerStyles.logo}><JugSaxonyLogo /></div>
        </Link>
        <div className={`${headerStyles.headerItem} ${headerStyles.navDropdown}`}>
          <Link className={`${headerStyles.dropbtn}`} to="/day" activeClassName={`${headerStyles.activeDropbtn}`}
                partiallyActive={true}>
            <button className={headerStyles.dropbtn}>Day</button>
          </Link>
          <SubHeader location={this.props.location}
                     destinationNames={dayDestinationNames}
                     destinations={dayDestinations}
                     key="/day" root="day" />
        </div>
        <div className={`${headerStyles.headerItem} ${headerStyles.navDropdown}`}>
          <Link className={`${headerStyles.dropbtn}`} to="/camp" activeClassName={`${headerStyles.activeDropbtn}`}
                partiallyActive={true}>
            <button className={headerStyles.dropbtn}>Camp</button>
          </Link>
          <SubHeader location={this.props.location}
                     destinationNames={campDestinationNames}
                     destinations={campDestinations}
                     key="/camp" root="camp" />
        </div>
        <div className={`${headerStyles.headerItem} ${headerStyles.navDropdown}`}>
          <Link className={`${headerStyles.dropbtn}`} to="/partner" activeClassName={`${headerStyles.activeDropbtn}`}
                partiallyActive={true}>
            <button className={headerStyles.dropbtn}>Partner</button>
          </Link>
          <SubHeader location={this.props.location}
                     destinationNames={partnerDestinationNames}
                     destinations={partnerDestinations}
                     key="/partner" root="partner" />
        </div>
        <div className={headerStyles.border}></div>
      </div>
    )
  }
}

export default Header
