import React from "react"
import { Link } from "gatsby"
import { FaVimeoV } from "@react-icons/all-files/fa/FaVimeoV"
import { FaXing } from "@react-icons/all-files/fa/FaXing"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaCalendarAlt } from "@react-icons/all-files/fa/FaCalendarAlt"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import * as footerStyles from "./footer.module.scss"
import Duke from "../../assets/duke-green.svg"

function Footer() {
    return(
        <footer className="footer">
            <fieldset>
                <legend align="center"><div className={footerStyles.logo}><Duke /></div></legend>
                <div className={footerStyles.footerContent}>
                    <div className={footerStyles.footerLinks}>
                        <Link className={footerStyles.footerLink} to="/impressum">Impressum</Link>
                        <Link className={footerStyles.footerLink} to="/datenschutzbedingungen">Datenschutz</Link>
                        <Link className={footerStyles.footerLink} to="/teilnahmebedingungen">Teilnahmebedingungen</Link>
                        <Link className={footerStyles.footerLink} to="/kontakt">Kontakt</Link>
                        <Link className={footerStyles.footerLink} to="/medienservice">Medienservice</Link>
                        <Link className={footerStyles.footerLink} to="/verhaltensregeln">Verhaltensregeln</Link>
                        <Link className={footerStyles.footerLink} to="/agb">AGB</Link>
                        <Link className={footerStyles.footerLink} to="/newsletter">Newsletter</Link>
                        <a href="https://backoffice.jugsaxony.org" className={footerStyles.footerLink} target="_blank">Backoffice</a>
                    </div>
                    <div className={footerStyles.footerIcons}>
                        <a className={footerStyles.footerIcon} href="https://backoffice.jugsaxony.org/calendars/events.ics" rel="noreferrer" download>
                            <FaCalendarAlt/>
                        </a>
                        <a className={footerStyles.footerIcon} href="https://twitter.com/jugsaxony" target="_blank" rel="noreferrer">
                            <FaTwitter/>
                        </a>
                        <a className={footerStyles.footerIcon} href="https://www.linkedin.com/company/jug-saxony-e-v/" target="_blank" rel="noreferrer">
                            <FaLinkedinIn/>
                        </a>
                        <a className={footerStyles.footerIcon} href="https://www.xing.com/pages/jug-saxony-e-v" target="_blank" rel="noreferrer">
                            <FaXing/>
                        </a>
                        <a className={footerStyles.footerIcon} href="https://www.instagram.com/jugsaxony/" target="_blank" rel="noreferrer">
                            <FaInstagram />
                        </a>
                        <a className={footerStyles.footerIcon} href="https://vimeo.com/jugsaxony" target="_blank" rel="noreferrer">
                            <FaVimeoV/>
                        </a>
                    </div>
                </div>
            </fieldset>
        </footer>
    )
}

export default Footer
