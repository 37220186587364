import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header"
import * as layoutStyles from "./layout.module.scss" 

const getPages = (markdownPages) => {
  const pages = []
  markdownPages.forEach((el) => {
    const field = el.node.fields.slug
    const parts = field.split("/").filter(Boolean)
    pages.push(parts[parts.length - 1])
  })

  return pages
}

const getPageNames = (markdownPages) => {
  const pageNames = []
  markdownPages.forEach((el) => {
    const pageName = el.node.frontmatter.headerName
    pageNames.push(pageName)
  })

  return pageNames
}

const getYears = (timelineEntries) => {
  const years = []
  timelineEntries.forEach((timelineEntry) => {
    const entryDate = new Date(timelineEntry.node.frontmatter.date)
    const entryYear = entryDate.getFullYear()
    if(!years.includes(entryYear)) {
      years.push(entryYear)
    }
  })
  return years
}

const getYearPaths = (years) => {
  const yearPaths = []
  years.forEach(year => {
    const yearPath = "#" + year
    yearPaths.push(yearPath)
  })
  return yearPaths
}

const Layout = (props) => {
    const { children, location } = props
    const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/camp/(index.md)/"}}) {
          edges {
            node {
              frontmatter {
                takesPlace
              }
            }
          }
        }
        verein: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/verein/pages/"}}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                headerName
              }
            }
          }
        }
        day: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/day/pages/"}}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                headerName
              }
            }
          }
        }
        partner: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/partner/pages/"}}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                headerName
              }
            }
          }
        }
        camp: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/camp/pages/"}}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                headerName
              }
            }
          }
        }
        timeline: allMarkdownRemark (sort: {fields: [frontmatter___date], order: ASC}, filter: {fileAbsolutePath: {regex: "/timeline/timeline/"  }}){
          edges {
            node {
              frontmatter {
                date
              }
            }
          }  
        }
      }
    `)

    const takesPlace = data.allMarkdownRemark.edges[0].node.frontmatter.takesPlace
    const verein = data.verein.edges
    const day = data.day.edges
    const partner = data.partner.edges
    const camp = data.camp.edges
    const timeline = data.timeline.edges

    const vereinPageNames = getPageNames(verein)
    const dayPageNames = getPageNames(day)
    const partnerPageNames = getPageNames(partner)
    const campPageNames = getPageNames(camp)
    const timelinePageNames = getYears(timeline)

    const vereinPages = getPages(verein)
    const dayPages = getPages(day)
    const partnerPages = getPages(partner)
    const campPages = getPages(camp)
    const timelinePages = getYearPaths(timelinePageNames)
    
    const subheaderPages = {
      "verein": vereinPages,
      "day": dayPages,
      "partner": partnerPages,
      "camp": campPages,
      "timeline": timelinePages
    }

    const subheaderPageNames = {
      "verein": vereinPageNames,
      "day": dayPageNames,
      "partner": partnerPageNames,
      "camp": campPageNames,
      "timeline": timelinePageNames
    }

    return (
      <div className={`${layoutStyles.container}`}>
        <div className={layoutStyles.content}>
          <Header location={location} campTakesPlace={takesPlace} subHeaderPages={subheaderPages} subHeaderPageNames={subheaderPageNames}/>
          <main className="container">{children}</main>
        </div>
        <Footer />
      </div>
    )
}

export default Layout

