import React from "react"
import Link from "gatsby-link"
import * as headerStyles from "./header.module.scss"

class SubHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      leftArrowVisible: false,
      rightArrowVisible: true,
    }
    this.scrollbarRef = React.createRef()
    this.scrollbarItems = []
    this.currentScrollbarItemIndex = null
    this.scroll = this.scroll.bind(this)
    this.scrollLeft = this.scrollLeft.bind(this)
    this.scrollRight = this.scrollRight.bind(this)
  }

  componentDidMount() {
    this.alignSubHeader()
  }

  alignSubHeader() {
    const currentLocation = this.props.location.pathname.replaceAll("/", "")
    const headerLocation = this.props.root.replaceAll("/", "")
    if (currentLocation.startsWith(headerLocation)) {
      if (this.currentScrollbarItemIndex && this.currentScrollbarItemIndex < this.scrollbarItems.length) {
        const currentScrollbarItem = this.scrollbarItems[this.currentScrollbarItemIndex]
        let scrollOffset = currentScrollbarItem.offsetLeft - currentScrollbarItem.offsetWidth
        if (this.scrollbarRef.scrollLeft !== 0) {
          scrollOffset = scrollOffset - this.scrollbarRef.scrollLeft
        }
        this.scroll(scrollOffset)
      }
    }
  }

  scrollLeft() {
    this.scroll(-100)
  }

  scrollRight() {
    this.scroll(100)
  }

  scroll(scrollOffset) {
    this.scrollbarRef.scrollLeft += scrollOffset
    this.setScrollButtonVisibility()
  }

  setScrollButtonVisibility() {
    const maxScrollWidth = this.scrollbarRef.scrollWidth - this.scrollbarRef.clientWidth
    if (this.scrollbarRef.scrollLeft === maxScrollWidth) {
      this.setState({ rightArrowVisible: false })
    } else {
      this.setState({ rightArrowVisible: true })
    }
    if (this.scrollbarRef.scrollLeft === 0) {
      this.setState({ leftArrowVisible: false })
    } else {
      this.setState({ leftArrowVisible: true })
    }
  }

  onNavigate(index) {
    this.currentScrollbarItemIndex = index
    this.alignSubHeader()
  }

  getTimelineYearItems() {
    const { destinationNames, destinations, location } = this.props
    let timelineDestinations = []
    for (let i = 0; i < destinationNames.length; i++) {
      timelineDestinations.push({
        destination: destinations[i],
        name: destinationNames[i],
      })
    }
    timelineDestinations = timelineDestinations.sort((a, b) => {
      if (a.name < b.name) {
        return 1
      } else if (a.name > b.name) {
        return -1
      } else {
        return 0
      }
    })
    const items = []
    for (let i = 0; i < timelineDestinations.length; i++) {
      const timelineDestination = timelineDestinations[i]
      if (timelineDestination.destination === this.props.location.hash) {
        this.currentScrollbarItemIndex = i
      }
      const pathname = location.pathname
      const pathParts = pathname.split("/")
      let active = false
      if (pathParts.length > 3) {
        active = "#" + pathParts[2] === timelineDestination.destination
      } else {
        active = location.hash === timelineDestination.destination || (location.hash === '' && i === 0)
      }
      items.push(
        <Link
          className={`${headerStyles.navLink} ${headerStyles.headerItem} ${active ? headerStyles.activeSecondLink : ""}`}
          to={`/timeline/${timelineDestination.destination}`} key={`/timeline/${timelineDestination.destination}`}
          ref={(ref) => this.scrollbarItems[i] = ref} onClick={() => this.onNavigate(i)}>
          {timelineDestination.name}
        </Link>,
      )
    }
    return items
  }

  getItems() {
    const { destinationNames, destinations } = this.props
    const items = []
    for (let i = 0; i < destinationNames.length; i++) {
      if (destinations[i] === this.props.location.pathname) {
        this.currentScrollbarItemIndex = i
      }
      items.push(
        <Link className={`${headerStyles.navLink} ${headerStyles.headerItem}`}
              activeClassName={`${headerStyles.activeSecondLink}`} partiallyActive={false}
              to={destinations[i]} key={destinations[i]} ref={(ref) => this.scrollbarItems[i] = ref}>
          {destinationNames[i]}
        </Link>,
      )
    }
    return items
  }

  render() {
    const { leftArrowVisible, rightArrowVisible } = this.state
    const { isTimeline } = this.props
    return (
      <div className={headerStyles.dropdownContent}>
        <div className={headerStyles.scrollbar}>
          <div onClick={() => this.scrollLeft()}
               className={`${headerStyles.navLink} ${headerStyles.headerItem} ${headerStyles.navigateScrollbarLeft}`}
               style={(leftArrowVisible) ? { visibility: "visible" } : { visibility: "hidden" }}>
            &#x2039;
          </div>
          <div onClick={() => this.scrollRight()}
               className={`${headerStyles.navLink} ${headerStyles.headerItem} ${headerStyles.navigateScrollbarRight}`}
               style={(rightArrowVisible) ? { visibility: "visible" } : { visibility: "hidden" }}>
            &#x203A;
          </div>
          <div className={headerStyles.scroll} ref={(ref) => this.scrollbarRef = ref}>
            {isTimeline ? <div>{this.getTimelineYearItems()}</div> : <div>{this.getItems()}</div>}
          </div>
        </div>
      </div>
    )
  }
}

export default SubHeader
